<template>
    <secured-area
      :operations="['List_Staff_Members', 'View_Staff_Member', 'Create_Staff_Member', 'Edit_Staff_Member', 'Remove_Staff_Member', 'View_Staff_Member_Capabilities', 'Set_Staff_Member_Capabilities']"
    >
        <h3>Liste des membres de staff</h3>
        <fieldset class="fieldset" v-if="userCanOneOf(['View_Staff_Member_Capabilities', 'Set_Staff_Member_Capabilities'])/*userHasOneOfRoles([this.Roles.Admin, this.Roles.StaffMember])*/">
            <legend>Options</legend>
            <b-checkbox switch v-model="showCapabilities">Voir les rôles</b-checkbox>
            <b-select
              v-show="showCapabilities"
              :options="competitions_types"
              v-model="competition_type"
            >
            </b-select>

            <b-select
              v-show="showCapabilities"
              :options="scopes"
              v-model="scope"
              prepend="Echelon"
            >

            </b-select>
        </fieldset>
        <div>{{staff.length}} membre du staff trouvés répondant aux critères</div>
        <detailed-staff-list
            :items="staff"
            :canAdd = "userCan('Create_Staff_Member')"
            :canView = "userCan('View_Staff_Member')"
            :canEdit = "userCanOneOf(['Edit_Staff_Member', 'Set_Staff_Member_Capabilities'])"
            :canRemove = "userCan('Remove_Staff_Member')"
            :showCapabilities = "showCapabilities && userCanOneOf(['View_Staff_Member_Capabilities', 'Set_Staff_Member_Capabilities']) "
            :showFirstname = "true"
            :showLastname = "true"
            :showRollnumber= "userCan('Edit_Staff_Member')"
            @add="addStaffMember"
            @edit="editStaffMember"
            @remove="showDeletionConfirmationDialog=true"
        >
        </detailed-staff-list>

        <b-modal
          v-model="showDeletionConfirmationDialog"
          @ok="removeStaffMember"
        >
        </b-modal>
    </secured-area>
</template>

<script>
import { mapActions } from "vuex";
import { REF_COMPETITION_TYPE, REF_SCOPE,  } from "@/LocalDB";
import DetailedStaffList from '@/components/DetailedStaffList';
import ModelViewsHelper from "@/services/store/models/ModelViews";
import SecuredArea from '../../components/Security/NewSecuredArea.vue';

export default {
    components: { DetailedStaffList, SecuredArea, },
    data(){
      return {
        showCapabilities: false,
        competition_type: 'CDF_OUT',
        scope: 'nation',
        showDeletionConfirmationDialog: false,
      }
    },
    computed:{
        staff(){
          if(!this.showCapabilities)
            return ModelViewsHelper.getAllDetailedStaffListItems();
          else
            return ModelViewsHelper.getDetailedStaffListItems(this.competition_type, this.scope, new Date().getFullYear(), null);
        },
        scopes(){
          return REF_SCOPE.all().map(item => { return { value: item.code, text: item.name }; });
        },
        competitions_types(){
          return REF_COMPETITION_TYPE.all().map(item => {return { value: item.code, text: item.name }});
        },
        canViewDetails(){
          return this.userHasOneOfRoles([this.Roles.Admin, this.Roles.StaffMember]);
        },

   },
    methods:{
      ...mapActions('auth', ['userCan', 'userCanOneOf']),

        addStaffMember(){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name: 'Staff'});
            })
        },
        editStaffMember(member){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name: 'Staff', params: { id: member}});
            });
        },
        removeStaffMember(member){
            this.$showRefresher()
              .then(() => {
                console.log("removeStaffMember", member);
                this.$hideRefresher();
              })
        }
    },
    mounted(){
      this.$hideLoader();
    }
}
</script>
